import React from 'react'
import Loadable from 'react-loadable'
import Loading from 'components/Loading/Loading'
import Layout, { Panel } from 'pages/Layouts/Layout'

// Leaderboards
export const LeaderboardsPage = Loadable({
	loader: () => import('pages/Leaderboards'),
	loading: Loading,
})
// Broadcasts
export const BroadcastPage = Loadable({
	loader: () => import('pages/Broadcast'),
	loading: Loading,
})
// Surveys
export const SurveyPage = Loadable({
	loader: () => import('pages/Survey'),
	loading: Loading,
})
// Settings
export const SettingsRoutesPage = Loadable({
	loader: () => import('pages/Settings/SettingsRoutes'),
	loading: Loading,
})
// Directory
export const DirectoryPage = Loadable({
	loader: () => import('pages/Member/Directory'),
	loading: Loading,
})
// Bookmarks
export const SavedItemsPage = Loadable({
	loader: () => import('pages/Member/SavedItems'),
	loading: Loading,
})
export const HomePage = Loadable({
	loader: () => import('pages/Home'),
	loading: Loading,
})
export const SignInPage = Loadable({
	loader: () => import('pages/SignIn'),
	loading: Loading,
})
export const SignOutPage = Loadable({
	loader: () => import('pages/SignOut'),
	loading: Loading,
})
export const ResetPassword = Loadable({
	loader: () => import('pages/ResetPassword'),
	loading: Loading,
})
export const TermsOfUsePage = Loadable({
	loader: () => import('pages/TermsOfUse'),
	loading: Loading,
})
export const LicensesPage = Loadable({
	loader: () => import('pages/Licenses/Licenses'),
	loading: Loading,
})
export const NotFoundPage = Loadable({
	loader: () => import('pages/NotFound'),
	loading: Loading,
})
export const NewsletterPage = Loadable({
	loader: () => import('pages/Newsletter'),
	loading: Loading,
})
export const NotificationsPage = Loadable({
	loader: () => import('pages/Notifications/Notifications'),
	loading: Loading,
})
export const MessagesPage = Loadable({
	loader: () => import('pages/Messages'),
	loading: Loading,
})
export const ProfilePage = Loadable({
	loader: () => import('pages/User/Profile'),
	loading: () => {
		return (
			<Layout>
				<Panel>
					<Loading />
				</Panel>
			</Layout>
		)
	},
})
export const PostDetailsPage = Loadable({
	loader: () => import('pages/Post/Details'),
	loading: () => {
		return (
			<Layout>
				<Panel>
					<Loading />
				</Panel>
			</Layout>
		)
	},
})
export const PostPreviewPage = Loadable({
	loader: () => import('pages/Preview/PostPreview'),
	loading: Loading,
})
export const ExternalFeedPage = Loadable({
	loader: () => import('pages/External/ExternalFeed'),
	loading: Loading,
})
export const PagePreviewPage = Loadable({
	loader: () => import('pages/Preview/PagePreview'),
	loading: Loading,
})
export const InvitePage = Loadable({
	loader: () => import('pages/Member/Invite'),
	loading: Loading,
})
export const ErrorPage = Loadable({
	loader: () => import('pages/Error'),
	loading: Loading,
})
export const CustomPages = Loadable({
	loader: () => import('pages/CustomPages'),
	loading: Loading,
})
export const DownloadsPage = Loadable({
	loader: () => import('pages/Downloads/Downloads'),
	loading: Loading,
})
export const EmailUnsubscribePage = Loadable({
	loader: () => import('pages/EmailUnsubscribe'),
	loading: Loading,
})
export const ConfirmUnsubscriptionPage = Loadable({
	loader: () => import('pages/ConfirmUnsubscription'),
	loading: Loading,
})
export const BetaPage = Loadable({
	loader: () => import('pages/Beta/Beta'),
	loading: Loading,
})
