import { decodeQueryParams, addQueryParam, encodeQueryParams } from '@dysi/js-helpers'
import { isDesktopApp } from 'helpers/browserhelper'

/**
 * NOTE:
 * routerPath: This is used by the router to assign components to routes.
 * linkPath: This is used for navigation in links, etc.
 */
const Routes = {
	Home: {
		routerPath: '/',
		linkPath: '/',
	},
	Category: {
		routerPath: '/category/:categoryId(\\d+)?',
		linkPath: '/category',
		generateLinkPath: id => `${Routes.Category.linkPath}/${id}`,
	},
	CustomPage: {
		routerPath: '/page/:slug*/:pageId',
		linkPath: '/page',
		generateLinkPath: id => `${Routes.CustomPage.linkPath}/${id}`,
	},
	SignIn: {
		routerPath: '/signin',
		linkPath: '/signin',
		generateLinkPath: (start, location = null) => {
			let url = `${Routes.SignIn.linkPath}`

			// construct the returnUrl (needs to be an absolute route, otherwise it won't be accepted)
			if (location) {
				const { search, protocol, host, pathname, href } = location
				let returnUrl = href

				const query = decodeQueryParams(search)
				if (query && query.returnUrl) {
					delete query.returnUrl
					const newQuery = encodeQueryParams(query)
					returnUrl = `${protocol}${host}${pathname}${newQuery}`
				}
				url = addQueryParam(url, 'returnUrl', returnUrl)
			}
			if (start === 'passwordReset') {
				url = addQueryParam(url, 'passwordReset', 'success')
			} else if (start) {
				url = addQueryParam(url, 'start', start)
			}

			return url
		},
	},
	SignOut: {
		// Generally only used for SSO
		routerPath: '/signout',
		linkPath: '/signout',
	},
	Broadcasts: {
		routerPath: '/broadcast/:broadcastId',
		linkPath: '/broadcast',
		generateLinkPath: id => `${Routes.Broadcasts.linkPath}/${id}`,
	},
	Newsletters: {
		routerPath: '/newsletter/:newsletterId',
		linkPath: '/newsletter',
		generateLinkPath: (id, broadcastId) => {
			let url = `${Routes.Newsletters.linkPath}/${id}`

			if (broadcastId) url += `?broadcastId=${broadcastId}`

			return url
		},
	},
	Notifications: {
		routerPath: '/notifications',
		linkPath: '/notifications',
	},
	Manage: {
		linkPath: '/manage',
	},
	Messages: {
		routerPath: '/messages/:conversationId?',
		linkPath: '/messages',
		generateLinkPath: (id, query, isArchived, isUnread, isToManagers) => {
			let url = `${Routes.Messages.linkPath}`

			if (id) url += `/${id}`
			if (query) url += `?search=${query}`
			if (isArchived) url += '?filter=archived'
			if (isUnread) url += '?filter=unread'
			if (isToManagers) url += '?managers=true'

			return url
		},
	},
	Post: {
		routerPath: '/post',

		Details: {
			routerPath: '/post/:slug*/:postId',
			linkPath: '/post',
			generateLinkPath: (
				slug,
				postId,
				showDiscussions,
				commentId,
				parentCommentId,
				activity = {}
			) => {
				const { activityReason, activitySource, activityDetail } = activity
				// Desktop App always needs showDiscussions to avoid external redirects DS-124
				let url = slug
					? showDiscussions || isDesktopApp
						? `${Routes.Post.Details.linkPath}/${slug}/${postId}?showDiscussions=true&`
						: `${Routes.Post.Details.linkPath}/${slug}/${postId}?`
					: showDiscussions || isDesktopApp
						? `${Routes.Post.Details.linkPath}/${postId}?showDiscussions=true&`
						: `${Routes.Post.Details.linkPath}/${postId}?`

				if (commentId) url += `commentId=${commentId}&`
				if (parentCommentId) url += `&parentCommentId=${parentCommentId}`
				if (activityReason) url += `actr=${activityReason}&`
				if (activitySource) url += `acts=${activitySource}&`
				if (activityDetail) url += `actd=${activityDetail}`
				if (url[url.length - 1] === '&' || url[url.length - 1] === '?') url = url.slice(0, -1)

				return url
			},
			generateDialogPath: (
				slug,
				postId,
				showDiscussions,
				commentId,
				parentCommentId,
				activity = {},
				autoPlayVideo
			) => {
				const { activityReason, activitySource, activityDetail } = activity
				const pathname = slug
					? `${Routes.Post.Details.linkPath}/${slug}/${postId}`
					: `${Routes.Post.Details.linkPath}/${postId}`
				let search = '?'
				// Desktop App always needs showDiscussions to avoid external redirects DS-124
				if (showDiscussions || isDesktopApp) search += 'showDiscussions=true'
				if (autoPlayVideo) search += 'autoPlay=true'
				if (commentId) search += `commentId=${commentId}`
				if (parentCommentId) search += `&parentCommentId=${parentCommentId}`
				if (activityReason) search += `actr=${activityReason}&`
				if (activitySource) search += `acts=${activitySource}&`
				if (activityDetail) search += `actd=${activityDetail}`
				if (search[search.length - 1] === '&' || search[search.length - 1] === '?')
					search = search.slice(0, -1)

				return {
					pathname,
					search,
					state: { showPageDialog: true },
				}
			},
		},
	},
	Preview: {
		routerPath: '/preview',

		Post: {
			routerPath: '/preview/post/:postId',
			linkPath: '/preview/post',
			generateLinkPath: postId => `${Routes.Preview.Post.linkPath}/${postId}`,
		},
		Page: {
			routerPath: '/preview/page/:pageId',
			linkPath: '/preview/page',
			generateLinkPath: pageId => `${Routes.Preview.Page.linkPath}/${pageId}`,
		},
	},
	External: {
		routerPath: '/feed',

		Feed: {
			routerPath: '/feed/cards/:feedId',
			linkPath: '/feed/cards',
			generateLinkPath: feedId => `${Routes.External.Feed.linkPath}/${feedId}`,
		},
	},
	Search: {
		routerPath: '/search',
		linkPath: '/search',
		generateLinkPath: (term, order) => {
			const encodedTerm = encodeURIComponent(term)
			return order ? `/search?q=${encodedTerm}&sortBy=${order}` : `/search?q=${encodedTerm}`
		},
	},
	// Submit: {
	// 	routerPath: '/submit',
	// 	linkPath: '/submit',
	// },
	User: {
		routerPath: '/user',
		linkPath: '/user',

		Profile: {
			routerPath: '/user/:userId(\\d+)?',
			linkPath: '/user',
			dialogPath: {
				pathname: '/user',
				state: { showPageDialog: true },
			},
			// TODO - Possibly update this to not use 1 but the sphereUserId instead. Or block all userId=1 from leaving the API
			generateLinkPath: id => (id && id !== 1 ? `${Routes.User.Profile.linkPath}/${id}` : null),
			generateDialogPath: id => {
				return {
					pathname: id && id !== 1 ? `${Routes.User.Profile.linkPath}/${id}` : null,
					state: { showPageDialog: true },
				}
			},
		},
	},
	ResetPassword: {
		routerPath: '/reset_password/:resetToken',
		// Should only be called from email (not from within app)
	},
	Settings: {
		routerPath: '/settings',
		linkPath: '/settings',

		Subscriptions: {
			routerPath: '/:path(settings|settings/subscriptions)',
			linkPath: '/settings/subscriptions',
		},
		Account: {
			routerPath: '/settings/account',
			linkPath: '/settings/account',
			generateLinkPath: anchor => `${Routes.Settings.Account.linkPath}#${anchor}`,
		},
		Profile: {
			routerPath: '/settings/profile',
			linkPath: '/settings/profile',
			generateLinkPath: anchor => `${Routes.Settings.Profile.linkPath}#${anchor}`,
		},
		Channels: {
			routerPath: '/settings/channels',
			linkPath: '/settings/channels',
		},
		Scheduling: {
			routerPath: '/settings/scheduling',
			linkPath: '/settings/scheduling',
		},
		Notifications: {
			routerPath: '/settings/notifications',
			linkPath: '/settings/notifications',
		},
		// Moved from /user
		MyShares: {
			routerPath: '/settings/shares',
			linkPath: '/settings/shares',
		},
		ScheduledShares: {
			routerPath: '/settings/scheduled',
			linkPath: '/settings/scheduled',
		},
		ApprovedContent: {
			routerPath: '/settings/approved',
			linkPath: '/settings/approved',
		},
		LoginActivity: {
			routerPath: '/settings/loginactivity',
			linkPath: '/settings/loginactivity',
		},
	},
	Survey: {
		routerPath: '/survey/:surveyId(\\d+)',
		linkPath: '/survey',
		generateLinkPath: id => `${Routes.Survey.linkPath}/${id}`,
	},
	Leaderboards: {
		routerPath: '/leaderboard/:leaderboardId(\\d+)?',
		linkPath: '/leaderboard',
		generateLinkPath: id => `${Routes.Leaderboards.linkPath}/${id}`,
	},
	Invite: {
		routerPath: '/invite',
		linkPath: '/invite',
	},
	Terms: {
		routerPath: '/terms',
		linkPath: '/terms',
	},
	Licenses: {
		routerPath: '/licenses',
		linkPath: '/licenses',
	},
	Directory: {
		routerPath: '/directory',
		linkPath: '/directory',
		generateLinkPath: (term, order = 'Contains') => {
			const encodedTerm = encodeURIComponent(term)
			return `${Routes.Directory.linkPath}/search?q=${encodedTerm}&sortBy=${order}`
		},
	},
	Downloads: {
		routerPath: '/downloads',
		linkPath: '/downloads',
	},
	SavedItems: {
		routerPath: '/saveditems',
		linkPath: '/saveditems',
	},
	EmailUnsubscribe: {
		routerPath: '/email/unsubscribe',
		linkPath: '/email/unsubscribe',
	},
	EmailConfirmUnsubscribe: {
		routerPath: '/email/confirmunsubscribe',
		linkPath: '/email/confirmunsubscribe',
	},
	Beta: {
		routerPath: '/beta',
		linkPath: '/beta',
	},
}

export default Routes
