import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

// Routing
import { Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import Routes from 'helpers/routes'

// Wrappers
import ErrorBoundary from 'wrappers/ErrorBoundary'
import withPageDialog from 'wrappers/PageDialogWrapper'

// Components
import Confetti from 'react-confetti'
import LocalStorage from 'pages/LocalStorage'

// Helpers
import { isLocalStorageAvailable } from '@dysi/js-helpers'
import { getColorSpectrum } from 'styles/themes/themeHelpers'
import { safeGetSetting } from 'wrappers/Settings'
import { safeGetThemeValue } from 'wrappers/Theme'
import { Theme } from 'wrappers/ThemeWrapper'
import { Settings } from 'wrappers/SettingsWrapper'
import { getIsImpersonating } from 'helpers/impersonationHelpers'

// Loadable Pages
import {
	LeaderboardsPage,
	BroadcastPage,
	SurveyPage,
	SettingsRoutesPage,
	DirectoryPage,
	SavedItemsPage,
	HomePage,
	SignInPage,
	SignOutPage,
	NotFoundPage,
	NewsletterPage,
	NotificationsPage,
	MessagesPage,
	ProfilePage,
	ResetPassword,
	PostDetailsPage,
	ExternalFeedPage,
	PostPreviewPage,
	PagePreviewPage,
	InvitePage,
	ErrorPage,
	CustomPages,
	DownloadsPage,
	TermsOfUsePage,
	LicensesPage,
	// EmailUnsubscribePage,
	BetaPage,
} from './LoadablePages'
import EmailUnsubscribePage from 'pages/EmailUnsubscribe'
import ConfirmUnsubscriptionPage from 'pages/ConfirmUnsubscription'
import { DysiBrandColor } from 'styles/variables'

// Wrap all pages with ErrorBoundary
const HomePageErrorBounded = ErrorBoundary(HomePage, ErrorPage)
const SignInPageErrorBounded = ErrorBoundary(SignInPage, ErrorPage)
const SignOutPageErrorBounded = ErrorBoundary(SignOutPage, ErrorPage)
const NewsletterPageErrorBounded = ErrorBoundary(NewsletterPage, ErrorPage)
const NotFoundPageErrorBounded = ErrorBoundary(NotFoundPage, ErrorPage)
const BroadcastPageErrorBounded = ErrorBoundary(BroadcastPage, ErrorPage)
const NotificationsPageErrorBounded = ErrorBoundary(NotificationsPage, ErrorPage)
const LeaderboardsPageErrorBounded = ErrorBoundary(LeaderboardsPage, ErrorPage)
const MessagesPageErrorBounded = ErrorBoundary(MessagesPage, ErrorPage)
const ProfilePageErrorBounded = ErrorBoundary(ProfilePage, ErrorPage)
const ResetPasswordPageErrorBounded = ErrorBoundary(ResetPassword, ErrorPage)
const SurveyPageErrorBounded = ErrorBoundary(SurveyPage, ErrorPage)
const PostDetailsRoutesPageErrorBounded = ErrorBoundary(PostDetailsPage, ErrorPage)
const ExternalFeedRoutesPageErrorBounded = ErrorBoundary(ExternalFeedPage, ErrorPage)
const PostPreviewRoutesPageErrorBounded = ErrorBoundary(PostPreviewPage, ErrorPage)
const PagePreviewRoutesPageErrorBounded = ErrorBoundary(PagePreviewPage, ErrorPage)
const SettingsRoutesPageErrorBounded = ErrorBoundary(SettingsRoutesPage, ErrorPage)
const DirectoryErrorBounded = ErrorBoundary(DirectoryPage, ErrorPage)
const SavedItemsErrorBounded = ErrorBoundary(SavedItemsPage, ErrorPage)
export const CustomPagesErrorBounded = ErrorBoundary(CustomPages, ErrorPage)
const DownloadsPageErrorBounded = ErrorBoundary(DownloadsPage, ErrorPage)
const TermsOfServiceErrorBounded = ErrorBoundary(TermsOfUsePage, ErrorPage)
const LicensesErrorBounded = ErrorBoundary(LicensesPage, ErrorPage)
const PostDialogErrorBounded = ErrorBoundary(withPageDialog(PostDetailsPage), ErrorPage)
const ProfileDialogErrorBounded = ErrorBoundary(withPageDialog(ProfilePage), ErrorPage)
const BetaPageErrorBounded = ErrorBoundary(BetaPage, ErrorPage)
const InvitePageErrorBounded = ErrorBoundary(InvitePage, ErrorPage)
const EmailUnsubscribePageErrorBounded = ErrorBoundary(EmailUnsubscribePage, ErrorPage)
const EmailConfirmUnsubscribePageErrorBounded = ErrorBoundary(ConfirmUnsubscriptionPage, ErrorPage)

const mapStateToProps = (state, ownProps) => {
	const communityName = state.sphere.communityName
	const enableAnonymousAccess = safeGetSetting(state, Settings.Features.EnableAnonymousAccess)
	const enableLeaderboards = safeGetSetting(state, Settings.Features.EnableLeaderboards)
	const enableMessages = safeGetSetting(state, Settings.Features.EnableMessages)
	const enableNewMemberInvitations = safeGetSetting(
		state,
		Settings.Features.EnableNewMemberInvitations
	)
	const enableUserDirectory = safeGetSetting(state, Settings.Features.EnableUserDirectory)
	const enableCustomPages = safeGetSetting(state, Settings.Features.EnableCustomPages)
	const enablePartyMode = safeGetSetting(state, Settings.Features.EnablePartyMode)
	const errors = state.errors
	const isImpersonating = getIsImpersonating()
	const isSharePostPending = state.post.isSharePostPending
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const sharePost = state.post.sharePost
	const userIsLoggedIn = safeGetSetting(state, Settings.User.IsLoggedIn)
	return {
		communityName,
		enableAnonymousAccess,
		enableCustomPages,
		enableLeaderboards,
		enableMessages,
		enableNewMemberInvitations,
		enableUserDirectory,
		enablePartyMode,
		errors,
		isImpersonating,
		isSharePostPending,
		primaryColor,
		sharePost,
		userIsLoggedIn,
	}
}

// Presentational Component
class AppRoutes extends React.Component {
	loggedInOrAnonymousAccessAllowed = () => {
		const { userIsLoggedIn, enableAnonymousAccess } = this.props

		return enableAnonymousAccess || userIsLoggedIn
	}

	previousLocation = this.props.location

	componentDidUpdate(prevProps) {
		const { location } = this.props

		if (!location.state || !location.state.showPageDialog) {
			this.previousLocation = location
		}
	}

	render() {
		const {
			communityName,
			enableAnonymousAccess,
			enableCustomPages,
			enableLeaderboards,
			enableMessages,
			enableNewMemberInvitations,
			enableUserDirectory,
			enablePartyMode,
			errors,
			isImpersonating,
			location,
			primaryColor,
			userIsLoggedIn,
		} = this.props

		const showPageDialog =
			location.state && location.state.showPageDialog && this.previousLocation !== location

		// If localStorage is unavailable, STOP!
		if (!isLocalStorageAvailable) {
			return <LocalStorage />
		}

		if (errors[location.pathname]) {
			// TODO: Add different types of error checks
			return <ProtectedRoute component={NotFoundPage} />
		} else {
			return (
				<Fragment>
					<Helmet>
						<title>{communityName}</title>
					</Helmet>
					{enablePartyMode && (
						<Confetti
							width={window.innerWidth}
							height={window.innerHeight}
							gravity={0.075}
							colors={[
								...getColorSpectrum(primaryColor).map(color => color.toHexString()),
								DysiBrandColor.dsBrandSecondaryGreyDark,
								DysiBrandColor.dsBrandSecondaryGreyLight,
							]}
						/>
					)}
					<Switch location={showPageDialog ? this.previousLocation : location}>
						{/* Pages */}
						{/* NOTE: Look inside ProtectedRoute for more info on its usage */}
						<ProtectedRoute
							exact
							path={Routes.Home.routerPath}
							component={HomePageErrorBounded}
							authorized={() => enableAnonymousAccess || userIsLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={Routes.Search.routerPath}
							component={HomePageErrorBounded}
						/>
						<ProtectedRoute
							exact
							path={Routes.Category.routerPath}
							component={HomePageErrorBounded}
							authorized={() => enableAnonymousAccess || userIsLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={Routes.SignIn.routerPath}
							component={SignInPageErrorBounded}
							ignoreEnhancedSecurity
							ignoreProfileIncompleteCheck
						/>
						<ProtectedRoute
							exact
							path={Routes.SignOut.routerPath}
							component={SignOutPageErrorBounded}
							ignoreEnhancedSecurity
							ignoreProfileIncompleteCheck
						/>
						<ProtectedRoute
							exact
							path={Routes.Newsletters.routerPath}
							component={NewsletterPageErrorBounded}
						/>
						<ProtectedRoute
							exact
							path={Routes.CustomPage.routerPath}
							component={CustomPagesErrorBounded}
							enabled={() => enableCustomPages}
						/>
						<ProtectedRoute
							exact
							path={Routes.Messages.routerPath}
							component={MessagesPageErrorBounded}
							authorized={() => userIsLoggedIn}
							enabled={() => enableMessages}
							allowImpersonation={false}
							isImpersonating={isImpersonating}
						/>
						<ProtectedRoute
							exact
							path={Routes.Broadcasts.routerPath}
							component={BroadcastPageErrorBounded}
							authorized={() => userIsLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={Routes.Notifications.routerPath}
							component={NotificationsPageErrorBounded}
							authorized={() => userIsLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={Routes.Leaderboards.routerPath}
							component={LeaderboardsPageErrorBounded}
							enabled={() => enableLeaderboards}
							authorized={() => userIsLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={Routes.Survey.routerPath}
							component={SurveyPageErrorBounded}
							authorized={() => userIsLoggedIn}
						/>
						<ProtectedRoute
							path={Routes.Directory.routerPath}
							component={DirectoryErrorBounded}
							authorized={() => userIsLoggedIn}
							enabled={() => enableUserDirectory}
						/>
						<ProtectedRoute
							path={Routes.SavedItems.routerPath}
							component={SavedItemsErrorBounded}
							authorized={() => userIsLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={Routes.External.Feed.routerPath}
							component={ExternalFeedRoutesPageErrorBounded}
							ignoreEnhancedSecurity
						/>
						<ProtectedRoute
							exact
							path={Routes.Preview.Post.routerPath}
							component={PostPreviewRoutesPageErrorBounded}
							ignoreEnhancedSecurity
						/>
						<ProtectedRoute
							exact
							path={Routes.Preview.Page.routerPath}
							component={PagePreviewRoutesPageErrorBounded}
							ignoreEnhancedSecurity
						/>
						<ProtectedRoute
							exact
							path={Routes.Post.Details.routerPath}
							component={PostDetailsRoutesPageErrorBounded}
						/>
						<ProtectedRoute
							exact
							path={Routes.ResetPassword.routerPath}
							component={ResetPasswordPageErrorBounded}
							ignoreEnhancedSecurity
							ignoreProfileIncompleteCheck
						/>
						<ProtectedRoute
							path={Routes.User.Profile.routerPath}
							component={ProfilePageErrorBounded}
							authorized={() => enableAnonymousAccess || userIsLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={Routes.Invite.routerPath}
							component={InvitePageErrorBounded}
							authorized={() => userIsLoggedIn}
							enabled={() => enableNewMemberInvitations}
							allowImpersonation={false}
							isImpersonating={isImpersonating}
						/>
						<ProtectedRoute
							exact
							path={Routes.EmailUnsubscribe.routerPath}
							component={EmailUnsubscribePageErrorBounded}
							ignoreEnhancedSecurity
							ignoreProfileIncompleteCheck
						/>
						<ProtectedRoute
							exact
							path={Routes.EmailConfirmUnsubscribe.routerPath}
							component={EmailConfirmUnsubscribePageErrorBounded}
							ignoreEnhancedSecurity
							ignoreProfileIncompleteCheck
						/>
						<ProtectedRoute
							exact
							path={Routes.Terms.routerPath}
							component={TermsOfServiceErrorBounded}
							ignoreEnhancedSecurity
							ignoreProfileIncompleteCheck
						/>
						<ProtectedRoute
							exact
							path={Routes.Licenses.routerPath}
							component={LicensesErrorBounded}
							ignoreEnhancedSecurity
							ignoreProfileIncompleteCheck
						/>
						<ProtectedRoute exact path={Routes.Beta.routerPath} component={BetaPageErrorBounded} />
						<ProtectedRoute
							exact
							path={Routes.Downloads.routerPath}
							component={DownloadsPageErrorBounded}
						/>
						{/* Pages with sub-routes */}
						<ProtectedRoute
							path={Routes.Settings.routerPath}
							component={SettingsRoutesPageErrorBounded}
							authorized={() => userIsLoggedIn}
						/>
						{/* Not Found */}
						<ProtectedRoute
							render={props => {
								return <NotFoundPageErrorBounded {...props} />
							}}
						/>
					</Switch>

					{/* Post Dialog */}
					{showPageDialog && (
						<Switch>
							<ProtectedRoute
								exact
								path={Routes.Post.Details.routerPath}
								component={PostDialogErrorBounded}
							/>
							<ProtectedRoute
								exact
								path={Routes.User.Profile.routerPath}
								component={ProfileDialogErrorBounded}
							/>
						</Switch>
					)}
				</Fragment>
			)
		}
	}
}

AppRoutes.propTypes = {
	communityName: PropTypes.string,
	enableAnonymousAccess: Settings.Features.EnableAnonymousAccess.propType,
	enableCustomPages: Settings.Features.EnableCustomPages.propType,
	enableLeaderboards: Settings.Features.EnableLeaderboards.propType,
	enableMessages: Settings.Features.EnableMessages.propType,
	enableNewMemberInvitations: Settings.Features.EnableNewMemberInvitations.propType,
	enableUserDirectory: Settings.Features.EnableUserDirectory.propType,
	enablePartyMode: Settings.Features.EnablePartyMode.propType,
	errors: PropTypes.object,
	isImpersonating: PropTypes.bool,
	isSharePostPending: PropTypes.bool,
	primaryColor: Theme.PrimaryColor.propType,
	sharePost: PropTypes.object,
	userIsLoggedIn: Settings.User.IsLoggedIn.propType,
	location: PropTypes.shape({
		// Provided from
		pathname: PropTypes.string.isRequired,
	}).isRequired,
}

export default withRouter(connect(mapStateToProps)(AppRoutes))
