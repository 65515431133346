import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'

// Helpers
import { safeGetSetting } from 'wrappers/Settings'
import { Settings } from 'wrappers/SettingsWrapper'
import Theme, { safeGetThemeValue } from 'wrappers/Theme'

// Lang
import lang from './confirmUnsubscription.lang'

// Components
import Layout, { Panel, Section } from 'pages/Layouts/Layout'
import NotFoundPage from 'pages/NotFound'
import Routes from 'helpers/routes'
import './EmailUnsubscribe.css'
import { useSearchParams } from 'react-router-dom'
import {
	asyncGetNotificationsPreferences,
	asyncSetNotificationsPreferences,
} from 'scenes/Settings/NotificationSettings/notificationSettings.reducer'



const mapStateToProps = (state, ownProps) => {
	const communityName = safeGetSetting(state, Settings.Community.Name)
	const primaryColor = safeGetThemeValue(state, Theme.PrimaryColor)
	const { email } = queryString.parse(ownProps.location.search)
	const currentUserId = safeGetSetting(state, Settings.User.ID)


	return {
		communityName,
		primaryColor,
		email,
		currentUserId,
	}
}

const createSettingsLink = primaryColor => {
	var basePath = process.env.PUBLIC_URL

	let startSlash = '/'
	if (basePath.indexOf('/') === 0) {
		// Avoid accidentally adding double slashes
		startSlash = ''
	}

	const linkPath = startSlash + basePath + Routes.Settings.Notifications.routerPath
	return `<a style="color:${primaryColor}" href="${linkPath}">${lang.settings}</a>`
}

const mapDispatchToProps = dispatch => {
	return {
		setPreference: (id, preferences, subscribeToSms, smsPhoneNumber, smsReturnUrl, abortable) =>
			dispatch(
				asyncSetNotificationsPreferences(
					id,
					preferences,
					subscribeToSms,
					smsPhoneNumber,
					smsReturnUrl,
					abortable
				)
			),
		fetchNotificationsPreferences: id => dispatch(asyncGetNotificationsPreferences(id)),
	}
}


class ConfirmUnsubscription extends Component {
	state = {
		loadPopup: false,
	}
	static propTypes = {
		email: PropTypes.string.isRequired,
		communityName: Settings.Community.Name.propType.isRequired,
		primaryColor: Theme.PrimaryColor.propType,
		currentUserId: Settings.User.ID.propType,
	}

	componentDidMount() {
		const queryParams = new URLSearchParams(window.location.search)
		const url = `${Routes.EmailUnsubscribe.linkPath}?${queryParams.toString()}`

		const notificationPreferences = queryParams.get('notificationPreferences')
		const { currentUserId, fetchNotificationsPreferences } = this.props
		fetchNotificationsPreferences(currentUserId)
			.then(p => {
				p.preferences.forEach(element => {
					const notificationPreferences = queryParams.get('notificationPreferences')

					if (element.id == notificationPreferences && !element.enabled) {
						this.props.history.replace(url)
					}
				})
				this.setState({ loadPopup: true })
			})
			.catch(error => {
				if (error && error.code && error.code === 'request_aborted') {
					return
				}
			})
	}

	handleClick = () => {
		this.props.history.push(Routes.Category.linkPath)
	}

	handleUnsubscribe = () => {
		const queryParams = new URLSearchParams(window.location.search);
		const url = `${Routes.EmailUnsubscribe.linkPath}?${queryParams.toString()}`;
		const notificationPreferences = queryParams.get('notificationPreferences');

		const { currentUserId, setPreference } = this.props
		const preference = {}
		if (notificationPreferences) {
			notificationPreferences.split(',').forEach(pref => {
				preference[pref] = false
			})
		}
		setPreference(currentUserId, preference, this.abortable).catch(error => {
			if (error && error.code && error.code === 'request_aborted') {
				return
			}
		})
		this.props.history.replace(url);

	};

	render() {
		const { email, communityName, primaryColor, currentUserId } = this.props
		if (!email || !communityName) {
			return <NotFoundPage />
		}

		return this.state.loadPopup ? (
			<Layout>
				<Panel>
					<Section>
						<h2>{lang.title}</h2>
						<p
							dangerouslySetInnerHTML={{
								__html: lang.message(email, communityName, createSettingsLink(primaryColor)),
							}}
						/>
						<p>{lang.importantTip}</p>
					</Section>
					<div className="button-group">
						<button
							className="button keep-subscribed"
							style={{
								borderColor: primaryColor,
							}}
							onClick={this.handleClick}
						>
							No, Keep me Subscribed
						</button>
						<button
							className="button unsubscribe"
							style={{
								backgroundColor: primaryColor,
							}}
							onClick={this.handleUnsubscribe}
						>
							Yes, Unsubscribe
						</button>
					</div>
				</Panel>
			</Layout>
		) : (
			<Layout />
		)
	}
}

let wrappedEmailConfirmUnsubscribe = connect(mapStateToProps, mapDispatchToProps)(ConfirmUnsubscription)
export default wrappedEmailConfirmUnsubscribe
